var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "close-on-content-click": true, "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g(
                      {
                        staticClass:
                          "font-weight-bold caption mr-1 d-flex align-center",
                        attrs: { color: "secondary", text: "", outlined: "" },
                      },
                      on
                    ),
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { small: "" } },
                            [_vm._v("desktop_windows")]
                          ),
                          _vm._v(" Run from application "),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm.currentSpaceType === _vm.spaceTypes.VENDOR_SPACE
            ? _c(
                "v-card",
                {
                  staticStyle: { "overflow-y": "hidden" },
                  attrs: { flat: "" },
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-alert",
                        {
                          staticClass: "my-0",
                          attrs: {
                            "max-width": "600",
                            text: "",
                            prominent: "",
                            type: "info",
                          },
                        },
                        [
                          _vm._v(
                            " Querying data from applications is possible after distributing the given table to a research / education space. "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-list",
                _vm._l(_vm.appTypeMetaData, function (app, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c("QuerySnipperDialog", {
                        attrs: { connectorData: _vm.getConnectorData(app) },
                      }),
                    ],
                    1
                  )
                }),
                0
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }